//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
import { today } from "@/utils/dates";
import { numWord } from "@/utils/newModule";
export default {
  mixins: [ModalMixin],
  props: {
    id: {
      required: false
    },
    result: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      onComplete: null
    };
  },
  mounted() {},
  methods: {
    onOpen({
      onComplete
    }) {
      this.onComplete = onComplete;
    },
    downloadList(e) {
      e.preventDefault();
      let string = "";
      this.result.not_found_sku.forEach(item => {
        string += item + "\n";
      });
      const url = window.URL.createObjectURL(new Blob([string]));
      const link = document.createElement("a");
      link.href = url;
      const fileName = `Не найденные товары или товары с ошибками ${today("DD.MM.YYYY HH:mm")}`;
      link.setAttribute("download", `${fileName}.txt`); //&:

      document.body.appendChild(link);
      link.click();
    },
    async submit({
      close
    }) {
      close();
      // this.onComplete && this.onComplete(response)
      // this.$emit('complete', response)
    }
  },

  computed: {
    pluralProductsNotFound() {
      return numWord(this.result.not_found_sku.length, ["товар не найден или указан с ошибками", "товара не найдены или указаны с ошибками", "товаров не найдены или указаны с ошибками"]);
    },
    pluralProductsUpdated() {
      return numWord(this.result.updated, ["товар успешно обновлен", "товара успешно обновлены", "товаров успешно обновлены"]);
    },
    actions() {
      return [{
        name: "submit",
        text: "Спасибо",
        action: ({
          close
        }) => {
          this.submit({
            close
          });
        }
      }];
    }
  },
  components: {
    Modal
  }
};